h2{font-size: 3rem;}
.blockHeading{
    font-weight:bold;
    font-size: 2rem;
}
h3.smallHeading{
    font-size:1.6rem;
}

.sidebarBackground{
    background-color:#f4f5f7;
}

.disable{
    background-color: #f2f2f2 !important;
    pointer-events: none;
    font-weight: 100 !important;
    color:#ccc !important
}

.actions img{
    width:30px;
    height:auto;
    margin:0 5px;
    cursor: pointer;
}