.footerWrapper{
    text-align:right;
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    gap:70px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 15px;
}

.footerWrapper div:last-child{
    text-align: right;
}

.weekDays span{
    border-radius: 50%;
    border:1px solid #f2f2f2;
    background-color: #f2f2f2;
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
}
.weekDays span.active{
    background-color: #333;
    color:#fff;
}

.progressBar{
    color:#000;
}
.progressBar a{
    color:#333 !important;
    text-decoration: none;
    display:block;
}
.disable label{
    color:#ccc !important
}

.progressBar a span{
    cursor: pointer;
}

.listheading{
    background-color: #333 !important;
    font-size:1.5rem;
    color:#fff !important;
}
.listheading label{font-size:1.5rem;
    color:#fff !important;
    text-transform:capitalize;
}
.active label{
    font-weight: bold;
}
.progressBar .active{
    background-color: #f2f2f2 !important;
}
.progressBar span{
    display: block;
    padding:15px 10px;
}
.testButtonDiv{
    margin-top: 25px;
    text-align: center;
    margin-bottom: 10px;
}
.showGustAudienceData{
    height:100%;
    width:100%;
    overflow-x: scroll;
    overflow-y: scroll;
}
.gustAudienceStyle{
    margin-top: 8%;
    width: 100%;
    height: 300px;
}
    