.blockHeading{
    font-weight:bold;
    font-size: 2rem;
}
h3.smallHeading{
    font-size:1.6rem;
}

.rowHover:hover{
 border:2px solid #000;
}
.active{
 border:2px solid #000 !important;
}